/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import faqs from './faqs';

// import Head from '../../components/Head';
// import StandardPage from '../../components/StandardPage';
// import * as FAQ from '../../components/faqs';
// import FAQPage from '../../components/FAQPage';

export function FAQLink({ text, link }) {
  return (
    <a href={link}>
      <p className="subsubtitle" style={{ margin: '4px 0px' }}>
        {text}
      </p>
    </a>
  );
}

const FAQLinks = () => {
  return (
    <div className="wide col js ac mb8">
      <div style={{ height: '32px' }} />
      {Object.keys(faqs).map((faqKey, i) => {
        const faq = faqs[faqKey];
        return <FAQLink key={i} text={faq.title} link={faq.link} />;
      })}

      <div style={{ height: '32px' }} />
    </div>
  );
};

export default FAQLinks;
