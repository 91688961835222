import React from 'react';

// const XXX = {
//   link: '/faq/XXX/',
//   title: 'XXX?',
//   body: (
//     <div>
//       <p>XXX</p>
//     </div>
//   )
// };

const WhatIsDPC = {
  link: '/faq/what-is-dpc/',
  title: 'What is direct primary care (DPC)?',
  body: (
    <div>
      <p>
        DPC is a new way to get high quality, comprehensive healthcare from a doctor who cares, all
        for a low, flat monthly fee.
      </p>
      <p>
        DPC doctors ditch the broken healthcare system, leave their hellish hospital jobs, and start
        providing care at reasonable prices. It’s that simple. No insurance, no corporate health
        system, no middlemen. Just doctors and patients.
      </p>
      <p>
        Practically speaking, DPC is a membership model for healthcare. Every patient pays a flat
        monthly fee to become a member of the DPC practice. What are the benefits of membership? Put
        simply: access to a doctor that cares about you.
      </p>
      <p>
        To get a far more detailed answer, check out <a href="/">the DPC Nation homepage</a>.
      </p>
    </div>
  )
};

// const BenefitsOfMembership = {
//   link: '/faq/benefits-of-dpc/',
//   title: 'What are the benefits of direct primary care membership?',
//   body: (
//     <div>
//       <p>
//         Your DPC doctor is there when you need them. You get unlimited office visits at no
//         additional charge—and they'll be as long as you need. Typically you’ll be able to book a
//         same-day or next-day appointment, sometimes directly through the practice’s website. Often
//         you’ll have access to your doctor’s cell phone number, so you’ll be able to call or text
//         them any medical questions as needed. Simple diagnostics like strep tests can often be
//         performed in office for a small additional charge (or no charge at all). If you have a major
//         issue, your doctor will coordinate any specialist referrals or hospital care.
//       </p>
//       <p>
//         For a comprehensive description of services covered by most DPC pratices, check out{' '}
//         <a href="/faq/what-is-covered">"What does a DPC membership include?"</a>.
//       </p>
//     </div>
//   )
// };

const HowMuchDoesItCost = {
  link: '/faq/how-much-does-it-cost/',
  title: 'How much does direct primary care cost?',
  body: (
    <div>
      <p>
        The typical monthly fee is about as much as your cell phone bill. That's a small price to
        pay for a level of care you literally can't get anywhere else. Imagine asking your hospital
        doctor for their personal cell number—they'd probably laugh out loud. Or call your local
        hospital and ask to schedule an appointment for tomorrow—not likely. Those things—any many
        more—come free with a DPC membership.
      </p>
      <p>
        Not to mention: the membership fee can often pay for itself. Most DPC practices have an
        established relationship with local laboratories and radiology centers. That means you can
        get blood tests, pathology screens, X-rays, MRIs, and more for low cash prices. Most DPC
        practices dispense medications in-house at near-wholesale prices, so you can both save money
        and avoid extra trips to the pharmacy. Plus, as a DPC member, you may be able to save a lot
        of money on insurance by switching to a high-deductible plan. Learn more about saving money
        with DPC <a href="/faq/dpc-saves-you-money">here</a>.
      </p>
      <p>
        In short: being a member of a DPC practice is like having a doctor in the family. Your
        doctor will always be in your corner, working to keep you as healthy as possible. It’s an
        amazing feeling.
      </p>
    </div>
  )
};

const SaveMoney = {
  link: '/faq/dpc-saves-you-money/',
  title: 'Can direct primary care save you money?',
  body: (
    <div>
      <p>You bet! Let's go over some common scenarios where a DPC membership can save you money.</p>
      <ul style={{ marginLeft: '20px' }}>
        <li style={{ marginTop: '30px' }}>
          <p>
            <b style={{ fontWeight: '600' }}>#1 You're taking multiple medications.</b> If you're
            taking multiple medications and paying with insurance for all of them, your co-pays are
            probably stacking up. Direct primary care practices frequently dispense common
            medications directly from their offices, so you won't need to do an extra trip to the
            pharmacy. Plus, your DPC doctor will purchase these medications at wholesale prices from
            a free-market vendor like Andameds or Henry Schein, and they pass those savings through
            to you. Some practices add a small markup (say, 10%), others sell these medications at
            cost. The value of this service depends on which medications you're taking. Often your
            insurance provider will only cover a drug from a particular drug manufacturer, or
            they'll require you to use a brand-name version even though a cheaper generic is on the
            market. If that's the case you can easily be paying hundreds of dollars a month on
            medications—money you could save as a DPC member.
          </p>
        </li>
        <li style={{ marginTop: '30px' }}>
          <p>
            <b style={{ fontWeight: '600' }}>#2 You get injured.</b> If you get in an accident,
            break a bone, need stitches, or require emergency medical care of any sort, you'll
            probably go to an urgent care or the emergency room. Going to either of these is a
            surefire way to drain your pocketbook. In the best base scenario, the urgent care center
            is in your insurance network and you pay a high co-pay—up to $100 in some cases. But
            that's only if you're lucky. Insurance coverage of emergency care is spotty at best, and
            urgent care centers are known to mislead patients regarding the true costs of care. Even
            if you verify multiple times that your insurance will cover your care, you can still{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.usatoday.com/story/news/2015/07/22/kaiser-beware--fees--urgent-care-centers/30244525/">
              get hit with big bills
            </a>
            . Often urgent cares don't know if you're insurance is actually valid, or they'll
            intentionally mislead you.
          </p>
          <p>
            Emergency rooms are even worse: you'll often have to pay a co-pay of $1000 or more just
            to get seen, PLUS the cost of any additional scans or treatments. There are{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.vox.com/2018/5/1/17261488/er-expensive-medical-bill">
              dozens
            </a>{' '}
            of{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://https://www.pbs.org/newshour/show/why-there-are-shocking-costs-on-your-emergency-room-bill">
              horror
            </a>{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.moneyunder30.com/paying-medical-bills-you-cant-afford">
              stories
            </a>{' '}
            online about unwitting patients who we're financially ruined by insane ER bills they
            thought we're covered by insurance. One bad day can cost you thousands of dollars—enough
            to cover years of a DPC membership. As a DPC patient, you know exactly who to call when
            you need help.
          </p>
        </li>
        <li style={{ marginTop: '30px' }}>
          <p>
            <b style={{ fontWeight: '600' }}>#3 You're overpaying for insurance.</b> If you're on an
            insurance plan named after a precious metal, you shouldn't be. Health insurance
            companies have been over-reaching for years—to read more about this, check out our{' '}
            <a href="/essays/dpc-solves-the-healthcare-crisis/">essay on the healthcare crisis</a>.
            Even the cheapest health plan your employer offers covers WAY more than it should, so
            don't get conned out of more money by picking a low-deductible "Platinum" plan.
          </p>
          <p>
            As a DPC patient, the majority of your healthcare will be taken care of by your DPC
            doctor. All standard care, wound treatment, blood work, preventative screens, and
            physicals will either be included in your membership or provided at a fair price by your
            doctor. If your DPC doctor isn't equipped to deal with a medical issue, it's probably
            serious. And if it's serious, then you'll very likely hit your out-of-pocket maximum
            (OOP). (This OOP is what it sounds like: the maximum amount you can possibly spend on
            healthcare before your insurance plan starts footing 100$ of the bill.)
          </p>
          <p>
            Here's the thing: the OOP for the cheapest and most expensive plans don't vary that
            much. Aetna's cheapest plan costs roughly $600 per year and has an OOP of $6650. Their
            most expensive plan costs $4200/year with an OOP of $4000. That means you'd be paying a
            guaranteed $3600 extra dollars ($4200 - $600) to avoid{' '}
            <em style={{ fontStyle: 'italic' }}>the small chance</em> of paying an extra $2650 (6650
            - 4000) on your healthcare. That doesn't make sense! Unless you're expecting to suffer a
            major health crisis every 9 months, you'll be better off on a cheaper plan. Otherwise,
            just join a DPC practice and take the cheapest insurance you can find!
          </p>
        </li>
      </ul>
      <p>Do none of those scenarios apply to to you? Here's one more:</p>
      <ul style={{ marginLeft: '20px' }}>
        <li style={{ marginTop: '30px' }}>
          <p>
            <b style={{ fontWeight: '600' }}>#4 You care about your health.</b> Here's the thing:
            direct primary care is the best game in town when it comes to healthcare. Think of it
            this way: before DPC, only movie stars and the super-rich were able to afford a personal
            doctor who works tirelessly to keep them healthy. To keep a personal doctor on staff
            costs around thousands of dollars per month. The rest of us had to a) wait until we were
            sick before talking to a doctor, then b) schedule a visit weeks in advance just to c)
            spend 10 minutes with a doctor we'd never met.
          </p>
          <p>
            With DPC, everyone can have a personal doctor on speed dial for a shockingly low amount.
            Having a medical expert at hand to answer questions, keep track of your preventative
            screenings, advise on your diet and lifestyle, and{' '}
            <em style={{ fontStyle: 'italic' }}>actually care</em> about your happiness was simply
            not possible before. Now it is. By splitting hairs over tens of dollars per month,
            you're missing out on the best deal in healthcare.
          </p>
        </li>
      </ul>
    </div>
  )
};

const WhyMembership = {
  link: '/faq/why-membership/',
  title: 'Why is direct primary care a monthly membership?',
  body: (
    <div>
      <p>
        If it seems like everything is a subscription these days, that’s probably because it is! And
        with good reason: subscriptions are the best way for a business (of any kind) to offer
        high-quality service and support over a long period of time. If you long for the days when
        software cost hundreds of dollars and came in a box, DPC might not be for you.
      </p>
      <p>
        The alternative would be itemized, "usage-based" pricing—the exact pricing scheme used by
        the traditional healthcare system. And that didn't work out so well for anyone. There are a
        hundred little perks of being a DPC patient—check out a comprehensive list of benefits{' '}
        <a href="/faq/benefits-of-dpc">here</a>. Frankly, the benefits of being a DPC patient are
        too varied and rich to be broken down into a "menu of services". Paying your DPC doctor for
        every thing they do would be like paying Netflix for every episode of TV you watch. Sounds
        awful.
      </p>
    </div>
  )
};

const SameAsConcierge = {
  link: '/faq/dpc-vs-concierge/',

  title: 'Is direct primary care the same as concierge medicine?',
  body: (
    <div>
      <p>
        You may have heard of a similar model called "concierge medicine". While there are
        similarities with DPC, the two are different in some fundamental ways. For starters,
        concierge practices often bill your insurance in addition to a monthly fee (though some
        don't—which makes them DPC practices!). This means they're still a part of the insurance
        industry's ridiculously complex reimbursement system, so they have to hire administrators to
        handle all the paperwork. This gets reflected in your monthly fee; the average concierge
        practice bills $200-300 per month. By comparison, DPC memberships cost approximately as much
        as your cell phone bill. Concierge was a step in the right direction, but it didn't go far
        enough. To bring sanity back to primary care, you need to cut insurance out of the picture
        entirely.
      </p>
    </div>
  )
};
const DoINeedInsurance = {
  link: '/faq/still-need-insurance/',

  title: 'Do I still need insurance as a direct primary care patient?',
  body: (
    <div>
      <p>Yes, you'll still need insurance, though you may be able to reduce the amount you pay.</p>
      <p>
        Your DPC membership is not an insurance plan; you'll still need insurance if you have a
        major health problem. (But when those problems arise, your DPC doctor will be next to you to
        guide you through the system and coordinate your care.) That said, you may be able to save
        money on your insurance! Since all routine care is covered by your membership, you can
        switch to a "high-deductible" health plan that costs less per month.{' '}
      </p>
      <p>
        Recently, some alternatives to traditional insurance companies have been gaining in
        popularity. You may actually be able to save money by joining a healthcare cost-sharing
        community. To learn more about how these communities go hand-in-hand with DPC, check out{' '}
        <a target="_blank" rel="noopener noreferrer" href="https://sedera.com/directprimarycare-2/">
          this article
        </a>{' '}
        by Sedera Health.
      </p>
    </div>
  )
};

const WhatIfIDontNeedFrequentCare = {
  link: '/faq/dpc-for-healthy-people/',

  title: "Should I join a direct primary care practice if I'm healthy?",
  body: (
    <div>
      <p>
        Absolutely! To be sure, your DPC doctor will always be there for you if you're get sick or
        hurt. But they also work proactively to keep you healthy, happy, free of preventable
        diseases, and living a good lifestyle.
      </p>
      <p>
        It's hard to break out of the mindset of the insurance-based healthcare system. In "the
        system", everything is centered around treating illness. It's very hard for a doctor to get
        paid by an insurance company for detecting early warning signs of diabetes or encouraging a
        lifestyle change to decrease risk of heart disease. Doctors just aren't rewarded for
        encouraging all-around good health.
      </p>
      <p>
        By being in constant contact with your DPC doctor when you're healthy, you'll be able to
        stay that way. By the time you're being treated for a chronic disease, it's usually too
        late. But if you spend your healthy years under the watchful eye of a doctor that cares,
        you'll be able to stay out of the hospital for years to come.
      </p>
    </div>
  )
};

const BenefitsOfDPCMembership = {
  link: '/faq/benefits-of-dpc/',

  title: 'What are the benefits of a direct primary care membership?',
  body: (
    <div>
      <p>Put simply: your DPC doctor is there when you need them. </p>
      <p>
        You get unlimited office visits at no additional charge—and they'll be as long as you need.
        Typically you’ll be able to book a same-day or next-day appointment, sometimes directly
        through the practice’s website. Often you’ll have access to your doctor’s cell phone number,
        so you’ll be able to call or text them any medical questions as needed. Some simple
        diagnostics (e.g. strep tests) and blood tests may be performed in office for a small
        additional charge (or no charge at all). If you have a major issue, your doctor will
        coordinate any specialist referrals or hospital care.
      </p>
      <p>
        Below is a comprehensive list of services provided by most direct primary care practices.
        Most of these services are provided as an included perk of membership; others are often
        provided for a small additional fee. Talk to the DPC practices near you to understand
        exactly what their memberships include.
      </p>
      <ul style={{ marginLeft: '28px' }}>
        <li style={{ marginTop: '10px' }}>
          <span style={{ fontWeight: 600 }}>Treating</span> common everyday problems like colds,
          flu, strep throat, rashes, burns, joint injuries, and other wounds.
        </li>
        <li style={{ marginTop: '10px' }}>
          <span style={{ fontWeight: 600 }}>Keeping</span> you up-to-date on all recommended
          preventative screening, like colonoscopies, pap smears, and EKGs.
        </li>
        <li style={{ marginTop: '10px' }}>
          <span style={{ fontWeight: 600 }}>Managing</span> chronic conditions like asthma,
          diabetes, high blood pressure, high cholesterol, or thyroid disorder. Your doctor will
          work with you to formulate a long-term personalized management plan.
        </li>
        <li style={{ marginTop: '10px' }}>
          <span style={{ fontWeight: 600 }}>Performing</span> minor procedures. If you need a minor
          procedure like a mole removal, joint injection, stitches, or a simple cosmetic precedure,
          your primary care provider may be able help with that. When joining a practice, ask your
          doctor what procedures are in their scope of practice.
        </li>

        <li style={{ marginTop: '10px' }}>
          <span style={{ fontWeight: 600 }}>Providing</span> a slew of additional services. A
          growing number of practices offer chronic pain treatments (joint injections), sleep
          disorder treatments (sleep studies and CPAP), minor cosmetic procedures, osteopathic
          manipulation, migraine treatments, osteoporosis tests (bone density testing), and athletic
          performance testing (VO2 max). These services are often offered for a small additional
          fee.
        </li>
        <li style={{ marginTop: '10px' }}>
          <span style={{ fontWeight: 600 }}>Managing</span> your specialist care. If you have a
          medical issue that can't be handled by your primary care physician, you'll be referred to
          a doctor that can continue your treatment. And your DPC doctor won't just shoo you out the
          door; they'll work closely wih the specialist to make sure there is seamless continuity of
          care. Many DPC doctors have access privileges at nearby hospitals, so they'll be able to
          check up on you and take an active role in your care.
        </li>
        <li style={{ marginTop: '10px' }}>
          <span style={{ fontWeight: 600 }}>Providing</span> an annual full-body physical and
          checkup. Often, your doctor will do a comprehensive review of systems at your very first
          in-person appointment.
        </li>
      </ul>
    </div>
  )
};

export default {
  // XXX,
  WhatIsDPC,
  BenefitsOfDPCMembership,
  HowMuchDoesItCost,
  SaveMoney,
  WhyMembership,
  SameAsConcierge,
  DoINeedInsurance,
  WhatIfIDontNeedFrequentCare
  // BenefitsOfMembership
};
