import React from 'react';
import { Button } from 'antd';

import LearnMore from './LearnMore';
import FAQLinks from './FAQLinks';
import faqs from './faqs';
import Head from './Head';
import StandardPage from './StandardPage';

export default function FAQPage(props) {
  const faq = faqs[props.index];
  return (
    <StandardPage>
      <Head title={faq.title} description="" url={faq.link} image="/img/banner.png" />
      <div className="col ac jc wrap" style={{ backgroundColor: 'white', padding: '100px 0px' }}>
        <div className="wide readable col js ac blog">
          <div style={{ height: '100px' }} />
          <h1 className="subtitle" style={{ width: '80%' }}>
            {faq.title}
          </h1>
          {faq.body}
          <div style={{ height: '100px' }} />
          <hr style={{ marginBottom: '1px solid lightgrey', width: '100%' }} />
          <div style={{ height: '100px' }} />
          <h3 className="subsubtitle tac">More related questions</h3>
          <FAQLinks />
          <Button href="/faq" size="large" type="primary">
            View All FAQs
          </Button>
          <div style={{ height: '100px' }} />
          <LearnMore whatisdpc crisis market />
        </div>
      </div>
    </StandardPage>
  );
}
